export class APIConstants {
  //public static readonly BASE_URL = 'https://localhost:44302/';

  //Production
  //public static readonly BASE_URL = 'https://intelpeek-video-conference.azurewebsites.net/';

  //UAT
  public static BASE_URL = 'https://twilioapi-dev-dev.solzit.com/';

  //DEV
  //public static readonly BASE_URL = 'https://intelpeek-video-conference-uat.azurewebsites.net/';
  public static readonly GET_ROOM = 'api/V1/Room/GetRoom/';
  public static readonly GET_ROOM_BY_ID = 'api/V1/Room/GetRoomById/';
  public static readonly UPDATE_ROOM = 'api/V1/Room/UpdateRoom/UpdateRoom';
  public static readonly COMPLETE_MEETING = 'api/V1/Room/UpdateRoomStatus/';
}
